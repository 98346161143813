import * as React from "react";

function SvgApp(props) {
  const { fill, gradient, height = "5em", width = "5em" } = props;
  return (
    <svg width={width} height={height} viewBox="0 0 128 128" fill={fill} >
      {gradient && gradient}
      <path d="M121.5 4.75H6.5A1.75 1.75 0 004.75 6.5v84.53a1.75 1.75 0 001.75 1.75h49.976L53.9 107.944A13.759 13.759 0 0042.35 121.5a1.75 1.75 0 001.75 1.75h71.4a7.759 7.759 0 007.75-7.75V6.5a1.75 1.75 0 00-1.75-1.75zM8.25 8.25h111.5v39.027a7.676 7.676 0 00-2.5-1.07V12.5a1.75 1.75 0 00-1.75-1.75h-103a1.75 1.75 0 00-1.75 1.75v66.53a1.75 1.75 0 001.75 1.75h62.055v8.5H8.25zM82.305 46a7.724 7.724 0 00-5.6 2.4H27.8a1.75 1.75 0 000 3.5h46.987a7.727 7.727 0 00-.232 1.849v3.423H27.8a1.75 1.75 0 000 3.5h46.755v5.273H27.8a1.75 1.75 0 000 3.5h46.755v7.835H14.25V14.25h99.5V46zm-8.2 61.936L71.524 92.78h3.031v15.226c-.149-.028-.3-.046-.449-.07zM67.973 92.78l2.549 14.97H57.479l2.548-14.97zM56.1 111.25h15.8a10.276 10.276 0 012.655.351v3.9a7.707 7.707 0 001.276 4.25H46a10.267 10.267 0 0110.1-8.501zm63.65 4.25a4.255 4.255 0 01-4.25 4.25H82.305a4.255 4.255 0 01-4.25-4.25V53.75a4.255 4.255 0 014.25-4.25H115.5a4.255 4.255 0 014.25 4.25z" />
      <path d="M18.5 20.93a1.749 1.749 0 00-1.75 1.75v9a1.75 1.75 0 003.5 0v-9a1.749 1.749 0 00-1.75-1.75zM27.8 25.583h72.4a1.75 1.75 0 000-3.5H27.8a1.75 1.75 0 000 3.5zM100.2 30.856H27.8a1.75 1.75 0 000 3.5h72.4a1.75 1.75 0 000-3.5zM100.2 39.628H27.8a1.75 1.75 0 100 3.5h72.4a1.75 1.75 0 000-3.5zM92.9 55.5h12a1.75 1.75 0 000-3.5h-12a1.75 1.75 0 100 3.5zM101.9 113.75h-6a1.75 1.75 0 100 3.5h6a1.75 1.75 0 000-3.5zM98.9 72.875a11.75 11.75 0 1011.75 11.75 11.764 11.764 0 00-11.75-11.75zm0 20a8.25 8.25 0 118.25-8.25 8.26 8.26 0 01-8.25 8.25z" />
      <path d="M101.853 80.223l-5.093 5.092-.808-.807a1.749 1.749 0 00-2.474 2.474l2.044 2.045a1.749 1.749 0 002.475 0l6.33-6.33a1.749 1.749 0 00-2.474-2.474z" />
    </svg>
  );
}

export default SvgApp;