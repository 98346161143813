import React, { Component } from 'react';
import ReactDOM from 'react-dom';

import './index.scss';
import Main from './dark/MainDemo';
import error404 from "./elements/error404";
import ContactConfirmation from './elements/ContactConfirmation';
import { BrowserRouter, Switch, Route } from 'react-router-dom';
import * as serviceWorker from './serviceWorker';

class Root extends Component {
    render() {
        return (
            <BrowserRouter basename={'/'}>
                <Switch>
                    <Route exact path={"/"} component={Main} />
                    <Route exact path={"/contact-confirmation"} component={ContactConfirmation} />
                    <Route path={"/404"} component={error404} />
                    <Route component={error404} />
                </Switch>
            </BrowserRouter>
        )
    }
}

ReactDOM.render(<Root />, document.getElementById('root'));
serviceWorker.register();