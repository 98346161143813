import React, { Component } from "react";
import ScrollToTop from "react-scroll-up";
import { FiChevronUp } from "react-icons/fi";
import Header from "../component/header/Header";
import Footer from "../component/footer/Footer";

import SliderOne from "../component/slider/SliderOne";
import ServiceTwo from "../elements/service/ServiceTwo";
import CounterOne from "../elements/counters/CounterOne";
import About from "../component/HomeLayout/homeOne/About";
import Portfolio from "../component/HomeLayout/homeOne/Portfolio";
import Contact from "../elements/contact/ContactTwo";
import BrandTwo from "../elements/BrandTwo";
import Helmet from "../component/common/Helmet";

class MainPage extends Component {
  render() {
    return (
      <div className="active-dark">
        <Helmet pageTitle="Home | Carrington Creative Company " />
        <Header
          id="home"
          headertransparent="header--transparent"
          colorblack="color--black"
          logo="dark"
        />

        {/* Start Slider Area   */}
        <div className="slider-wrapper">
          <SliderOne />
        </div>
        {/* End Slider Area   */}

        {/* Start About Area */}
        <div
          id="about"
          className="about-area about-position-top pb--120 bg_color--1">
          <About />
        </div>
        {/* End About Area */}

        {/* Start Service Area  */}
        <div
          id="service"
          className="service-area ptb--80  bg_image bg_image--3">
          <div className="container">
            <ServiceTwo />
          </div>
        </div>
        {/* End Service Area  */}

        {/* Start Portfolio Area */}
        <div id="work" className="portfolio-area ptb--120 bg_color--1">
          <div className="portfolio-sacousel-inner mb--55">
            <Portfolio />
          </div>
        </div>
        {/* End Portfolio Area */}

        {/* Start CounterUp Area */}
        <div className="rn-counterup-area ptb--120 active-dark">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="section-title text-center">
                  <h3 className="fontWeight500 custom-theme-gradient">
                    Our Fun Facts
                  </h3>
                </div>
              </div>
            </div>
            <CounterOne />
          </div>
        </div>
        {/* End CounterUp Area */}

        {/* Start Brand Area */}
        <div className="rn-brand-area active-dark">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <BrandTwo />
              </div>
            </div>
          </div>
        </div>
        {/* End Brand Area */}

        {/* Start Contact Area */}
        <div id="contact" className="rn-contact-area brand-separation">
          <Contact />
        </div>
        {/* End Brand Area */}

        {/* Start Back To Top */}
        <div className="backto-top">
          <ScrollToTop showUnder={160} duration={1000}>
            <FiChevronUp />
          </ScrollToTop>
        </div>
        {/* End Back To Top */}

        <Footer />
      </div>
    );
  }
}
export default MainPage;
