import React, { Component } from "react";
import { services } from "../../config/common";

class ServiceTwo extends Component {
  render() {
    let title = "Services",
      description =
        "We offer a variety of business services. If your problem requires a more customized solution, feel free to reach out! We will be more than happy to help.";
    return (
      <React.Fragment>
        <div className="row">
          <div className="col-lg-4 col-12">
            <div className="section-title mt--30 mt_md--5 mt_mobile--5 mb_mobile--10">
              <h2 className="title custom-theme-gradient">{title}</h2>
              <p>{description}</p>
              <div className="service-btn">
                <a className="btn-transparent rn-btn-dark" href="/#contact">
                  <span className="text">Request Our Services</span>
                </a>
              </div>
            </div>
          </div>
          <div className="col-lg-8 col-12 mt_md--50">
            <div className="row service-one-wrapper">
              {services.map((val, i) => (
                <div className="col-lg-6 col-md-6 col-sm-6 col-12" key={i}>
                  <a href="#contact">
                    <div className="service service__style--2">
                      <div className="icon">{val.iconSvgAlt}</div>
                      <div className="content">
                        <h3 className="title">{val.title}</h3>
                        <p>{val.description}</p>
                      </div>
                    </div>
                  </a>
                </div>
              ))}
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}
export default ServiceTwo;
