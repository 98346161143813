import * as React from "react";

function SvgVision(props) {
  const { fill, gradient, height = "5em", width = "5em" } = props;
  return (
    <svg viewBox="0 0 512 512" width={width} height={height} fill={fill} >
      {gradient && gradient}
      <path d="M112 136v16h8a24.027 24.027 0 0024-24v-8h-16v8a8.01 8.01 0 01-8 8z" />
      <circle cx={112} cy={104} r={8} />
      <path d="M456 72h-96a39.79 39.79 0 00-23.972 8H256a31.86 31.86 0 00-21.132 8H192V48a32.036 32.036 0 00-32-32H96a32.036 32.036 0 00-32 32v82a63.944 63.944 0 0026.506 51.82l-7.984 11.974L38.111 216A39.783 39.783 0 0016 251.777V296a7.992 7.992 0 00.18 1.676h-.002l.009.043v.003L40 408.847V488a8 8 0 008 8h160a8 8 0 008-8v-79.208l14.891-74.455 7.54 1.508A8.022 8.022 0 00240 336h56a8.001 8.001 0 006.554-3.412l112-160A8 8 0 00416 168v-16h40a40 40 0 000-80zm-96 16h64.022a39.913 39.913 0 000 48H360a24 24 0 010-48zM151.415 189.544l7.354 11.03-8.96 17.92L136 204.688v-11.205a63.453 63.453 0 0015.415-3.938zm-5.072 48.113a8 8 0 0012.812-2.08l12.708-25.415 16.066 6.426L147.91 272H136v-44.687zm51.243-34.44l-24.308-9.722-7.784-11.676A63.997 63.997 0 00191.71 136h43.158a32.094 32.094 0 007.646 5.01zM225.013 120H176a8 8 0 010-16h49.013a32.109 32.109 0 000 16zm35.077 24H304v12.687l-32.594 32.593-27.898-22.32zm18.36 60.864l39.207-39.207A7.999 7.999 0 00320 160v-16h16v28.907l-72.276 79.504-7.515-7.515zM256 96h67.35a39.886 39.886 0 000 32H256a16 16 0 010-32zm-96-64a16.018 16.018 0 0116 16v16h-64v-8a24.027 24.027 0 0124-24zm-64 0h8.022A39.79 39.79 0 0096 56v8H80V48a16.018 16.018 0 0116-16zm-16 98V80h96v8a23.997 23.997 0 00-.387 47.99A47.994 47.994 0 0180 130zm40 63.482v11.205l-13.809 13.808-8.96-17.92 7.354-11.03A63.453 63.453 0 00120 193.481zm-88 58.295a23.87 23.87 0 0113.267-21.466l39.155-19.578 12.423 24.845a8 8 0 0012.812 2.079L120 227.313V272H96v16h56a8 8 0 006.485-3.316l75.643-104.736 27.596 22.076L187.293 336H82.959L32 292.32zM54.467 400l-17.679-82.502 38.005 32.576A8.002 8.002 0 0080 352h40v48zM56 480v-64h144v64zm145.441-80H136v-48h56a8 8 0 006.993-4.115l9.964-17.935 6.245 1.249zm90.394-80h-51.043l-23.676-4.735 31.012-55.823 10.215 10.215A8 8 0 00264 272l.19-.002a8 8 0 005.73-2.617l75.588-83.147 28.143 16.886zM400 165.478l-17.151 24.502L352 171.47v-20.275a40.027 40.027 0 008 .805h40zM456 136a24 24 0 1124-24 24.027 24.027 0 01-24 24z" />
    </svg>
  );
}

export default SvgVision;