import React, { Component } from "react";

const quote = (
  <span>
    "There is always more value to be created. Let's get as close to the
    customer as possible to find it."
  </span>
);

const quoteAuthor = <span>Carrington Dennis, Founder</span>;

const title = "About";
const description = (
  <span>
    We take great pride and pleasure in helping founders transition their idea
    from being a concept, to being in the hands of their customers.
    <br />
    <br />
    Our development process involves customer discovery, product positioning,
    design, iteration, testing, and of course development.
    <br />
    <br />
    We place a high premium on being customer centric. As such, we put a lot of
    upfront effort in identifying potential risks (both technological and market
    forces) to ensure that the best possible outcome happens for founders and
    their customers.
  </span>
);

const whoWeAreDesciption = (
  <span>
    We are creatives, experts, and technology enthusiast.
    <br />
    <br />
    We passionately live to create and capture value through new and innovative
    products and services.
  </span>
);

class About extends Component {
  render() {
    return (
      <React.Fragment>
        <div className="about-wrapper">
          <div className="container">
            <div className="row row--35 align-items-center">
              <div className="col-lg-5 col-md-12">
                <div className="thumbnail">
                  <img
                    className="w-100"
                    src="/assets/images/about/carrington.jpg"
                    alt="About Images"
                  />
                  <p
                    style={{
                      fontWeight: "400",
                      fontFamily: "Poppins, sans-serif",
                      color: "#fff",
                      margin: "1em 0em",
                      textAlign: "center",
                    }}>
                    {quote} <br />- {quoteAuthor}
                  </p>
                </div>
              </div>

              <div className="col-lg-7 col-md-12">
                <div className="about-inner inner">
                  <div className="section-title">
                    <h2 className="title custom-theme-gradient">{title}</h2>
                    <p className="description">{description}</p>
                  </div>
                  <div className="row mt--30 mt_sm--10">
                    <div className="col-lg-8 col-md-12 col-sm-12 col-12">
                      <div className="about-us-list">
                        <h3 className="title custom-theme-gradient">
                          Who we are
                        </h3>
                        <p>{whoWeAreDesciption}</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}
export default About;
