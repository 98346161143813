import React, { Component } from "react";
class FooterSimple extends Component {
  scrollTo = (id) => {
    document.querySelector(`#${id}`).scrollIntoView({
      behavior: "smooth",
    });
  };

  render() {
    return (
      <React.Fragment>
        <footer className="footer-area">
          <div className="footer-wrapper">
            <div className="row align-items-end row--0">
              <div className="col-lg-12">
                <div className="footer-right" data-black-overlay="6">
                  <div className="row">
                    <div className="col-lg-12">
                      <div className="copyright-text">
                        <div>
                          <img
                            className="footer-logo"
                            src="/assets/images/logo/ccc-logo-light.png"
                            alt="Digital Agency"
                          />
                        </div>
                        <p>
                          Copyright © 2020 Carrington Creative Company <br />{" "}
                          All Rights Reserved.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </footer>
      </React.Fragment>
    );
  }
}
export default FooterSimple;
