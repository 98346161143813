import React, { Component, Fragment } from "react";
import CountUp from "react-countup";
import VisibilitySensor from "react-visibility-sensor";

class CounterOne extends Component {
  state = {
    didViewCountUp: false,
  };
  onVisibilityChange = (isVisible) => {
    if (isVisible) {
      this.setState({ didViewCountUp: true });
    }
  };
  render() {
    let Data = [
      {
        countNum: 12,
        countTitle: "Successfully product launched.",
      },
      {
        countNum: 5,
        countTitle:
          "In-house applications designed, developed, and tested for market viability.",
      },
      {
        countNum: 100,
        countTitle: "Smiles brought to customers and end users.",
        suffix: "k",
      },
    ];

    return (
      <Fragment>
        <div className="row">
          {Data.map((value, index) => (
            <div
              className="counterup_style--1 col-lg-4 col-md-4 col-sm-6 col-12"
              key={index}>
              <h5 className="counter custom-theme-gradient">
                <VisibilitySensor
                  onChange={this.onVisibilityChange}
                  offset={{ top: 10 }}
                  delayedCall>
                  <div>
                    {value.prefix && <span>{value.prefix}</span>}
                    <CountUp
                      end={this.state.didViewCountUp ? value.countNum : 0}
                    />
                    {value.suffix && <span>{value.suffix}</span>}
                  </div>
                </VisibilitySensor>
              </h5>
              <p className="description">{value.countTitle}</p>
            </div>
          ))}
        </div>
      </Fragment>
    );
  }
}
export default CounterOne;
