import React, { Component } from "react";
import { services } from "../../config/common";

class ServiceOne extends Component {
  render() {
    return (
      <React.Fragment>
        <div className="row">
          {services.map((val, i) => (
            <div className="col-lg-3 col-md-6 col-sm-6 col-12" key={i}>
              <div className="service service__style--1">
                <div className="icon">{val.iconSvg}</div>
                <div className="content">
                  <h4 className="title">{val.title}</h4>
                  <p>{val.description}</p>
                </div>
              </div>
            </div>
          ))}
        </div>
      </React.Fragment>
    );
  }
}
export default ServiceOne;
