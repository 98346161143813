import React, { Component } from "react";
import * as EmailValidator from "email-validator";

class ContactTwo extends Component {
  constructor(props) {
    super(props);
    this.state = {
      name: "",
      email: "",
      subject: "",
      message: "",
      error: null,
    };
  }

  onSubmit = (event) => {
    const target = event.currentTarget;
    const elements = target.elements;
    const { name, email, subject, message } = elements;
    const validEmail = EmailValidator.validate(email.value);
    const validSubmit =
      name.value && email.value && subject.value && validEmail && message.value;
    if (!validSubmit) {
      event.preventDefault();
      let error = "";
      if (!name.value) {
        error = "Please enter a name.";
      } else if (!email.value) {
        error = "Please enter an email.";
      } else if (!validEmail) {
        error = "Please enter a valid email address.";
      } else if (!subject.value) {
        error = "Please enter a subject.";
      } else if (!message.value) {
        error = "Please enter a message.";
      }
      if (error) {
        this.setState({ error });
      }
      return false;
    } else {
      this.setState({ error: null });
      return true;
    }
  };

  render() {
    const description =
      "Want to get in touch with us? Great, we would love to hear from you. Feel free send us a note.";
    return (
      <div className="contact-form--1">
        <div className="container">
          <div className="row row--35 align-items-start">
            <div className="col-lg-6 order-2 order-lg-1">
              <div className="section-title text-left mb--50">
                <h2 className="title custom-theme-gradient">Contact Us</h2>
                <p className="description">{description}</p>
              </div>
              <div className="form-wrapper">
                <form
                  id={"contact-form"}
                  onSubmit={this.onSubmit}
                  action="https://2ao150tdyc.execute-api.us-east-1.amazonaws.com/dev/"
                  method="post"
                >
                  <input type="hidden" name="_recaptcha" />
                  <input
                    type="hidden"
                    name="_to"
                    value="01d03dd890325bbfe3eba2351952696ced6aeb"
                    readOnly
                  />
                  <input
                    type="hidden"
                    name="_redirect"
                    value="https://carringtoncreative.company"
                    readOnly
                  />
                  <input
                    type="text"
                    name="_honeypot"
                    defaultValue=""
                    style={{ display: "none" }}
                  />

                  {this.state.error && (
                    <span style={{ color: "red", fontWeight: "600" }}>
                      {this.state.error}
                    </span>
                  )}

                  <label htmlFor="name">
                    <input
                      type="text"
                      name="name"
                      id="name"
                      required
                      defaultValue={this.state.name}
                      onChange={(e) => {
                        this.setState({ name: e.target.value });
                      }}
                      placeholder="Full Name *"
                    />
                  </label>
                  <label htmlFor="email">
                    <input
                      type="email"
                      name="email"
                      id="email"
                      required
                      defaultValue={this.state.email}
                      onChange={(e) => {
                        this.setState({ email: e.target.value });
                      }}
                      placeholder="Email Address *"
                    />
                  </label>

                  <label htmlFor="subject">
                    <input
                      type="text"
                      name="subject"
                      id="subject"
                      required
                      defaultValue={this.state.subject}
                      onChange={(e) => {
                        this.setState({ subject: e.target.value });
                      }}
                      placeholder="Subject *"
                    />
                  </label>
                  <label htmlFor="message">
                    <textarea
                      type="text"
                      id="message"
                      name="message"
                      required
                      defaultValue={this.state.message}
                      onChange={(e) => {
                        this.setState({ message: e.target.value });
                      }}
                      placeholder="Message *"
                    />
                  </label>
                  <button
                    className="rn-button-style--2 btn-solid"
                    type="submit"
                    value="submit"
                    id="mc-embedded-subscribe"
                  >
                    Submit
                  </button>
                </form>
              </div>
            </div>
            <div className="col-lg-6 order-1 order-lg-2">
              <div className="thumbnail mb_md--30 mb_sm--30">
                <img
                  style={{ filter: "saturate(1)" }}
                  src="/assets/images/about/contact.png"
                  alt="trydo"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
export default ContactTwo;
