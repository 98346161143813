import React, { Component } from "react";

class BrandTwo extends Component {
  render() {
    return (
      <React.Fragment>
        <ul className="brand-style-2">
          <li>
            <img
              style={{ filter: "contrast(0.75)" }}
              src="/assets/images/logo/cutman.png"
              alt="Logo Images"
            />
          </li>
          <li>
            <img
              style={{ filter: "contrast(0.75)" }}
              src="/assets/images/logo/peopleops.png"
              alt="Logo Images"
            />
          </li>
          <li>
            <img
              style={{ filter: "contrast(0.75)" }}
              src="/assets/images/logo/niteowt.png"
              alt="Logo Images"
            />
          </li>
          <li>
            <img
              style={{ filter: "contrast(0.75)" }}
              src="/assets/images/logo/insouts.png"
              alt="Logo Images"
            />
          </li>
          <li>
            <img
              src="/assets/images/logo/ainsleytroupe.png"
              alt="Logo Images"
            />
          </li>
          <li>
            <img
              style={{ filter: "contrast(0.75)" }}
              src="/assets/images/logo/iyb.png"
              alt="Logo Images"
            />
          </li>
          <li>
            <img
              style={{ filter: "contrast(0.75)" }}
              src="/assets/images/logo/bolt.png"
              alt="Logo Images"
            />
          </li>
        </ul>
      </React.Fragment>
    );
  }
}
export default BrandTwo;
