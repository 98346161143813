import React, { Component } from "react";
import Slider from "react-slick";
import { portfolioSlick2 } from "../../../page-demo/script";

const PortfolioList = [
  {
    image: "image-1",
    category: "Cutman.ai",
    title:
      "Combat sports management platform for fighters, matchmakers, and promoters.",
  },
  {
    image: "image-2",
    category: "InsOuts",
    title: "Modern scheduling application for barbers and barbershops.",
  },
  {
    image: "image-3",
    category: "PeopleOps",
    title:
      "Corporate leadership software for leaders looking to maximize company growth.",
  },
  {
    image: "image-4",
    category: "Niteowt",
    title:
      "Discover the best deals and promotions for bars and restaurants in your area.",
  },
];

class Portfolio extends Component {
  render() {
    let title = "Our Works",
      description =
        "We take pride in our work and our ability to execute on time and under budget.";
    return (
      <React.Fragment>
        <div id="works" className="portfolio-wrapper">
          <div className="container">
            <div className="row">
              <div className="col-lg-6">
                <div className="section-title">
                  <h2 className="title custom-theme-gradient">{title}</h2>
                  <p>{description}</p>
                </div>
              </div>
            </div>
          </div>
          <div className="portfolio-slick-activation mt--70 mt_sm--40">
            <Slider {...portfolioSlick2}>
              {PortfolioList.map((value, index) => (
                <div className="portfolio" key={index}>
                  <div className="thumbnail-inner">
                    <div className={`thumbnail ${value.image}`}></div>
                    <div className={`bg-blr-image ${value.image}`}></div>
                  </div>
                  <div className="content">
                    <div className="inner">
                      <p>{value.category}</p>
                      <h4>
                        <a href="#works">{value.title}</a>
                      </h4>
                      {/* <div className="portfolio-button">
                        <a className="rn-btn" href="/portfolio-details">
                          Case Study
                        </a>
                      </div> */}
                    </div>
                  </div>
                </div>
              ))}
            </Slider>
          </div>
        </div>
      </React.Fragment>
    );
  }
}
export default Portfolio;
