import React from "react";
import { SvgAi, SvgGoal, SvgVision, SvgApp } from "../elements/svg";

export const svgDef = (
  <defs>
    <linearGradient id="custom-gradient-fill" gradientTransform="rotate(45)">
      <stop offset="0%" stopColor="#01f8d6"></stop>
      <stop offset="100%" stopColor="#e6e407"></stop>
    </linearGradient>
  </defs>
);

const fill = "#fff";
export const customFill = "url('#custom-gradient-fill')";
export const services = [
  {
    id: "mobile-web",
    title: "Mobile & Web Dev.",
    description:
      "Building a company is difficult enough to have to worry about building software. Focus on your company’s vision and leave all of the technical complexity to us.",
    iconSvg: <SvgApp gradient={svgDef} fill={customFill} height={"5em"} width={"5em"} />,
    iconSvgAlt: <SvgApp fill={fill} height={"1em"} width={"1em"} />,
  },
  {
    id: "product",
    title: "Product Leadership",
    description:
      "Stop wasting valuable resources on building the wrong products. Our product leadership and practices will empower you validate your assumptions and create real value.",
    iconSvg: <SvgVision gradient={svgDef} fill={customFill} height={"5em"} width={"5em"} />,
    iconSvgAlt: <SvgVision fill={fill} height={"1em"} width={"1em"} />,
  },
  {
    id: "ai",
    title: "AI Strategy",
    description:
      "AI is here to stay and it will power the next generation of world changing applications. Leverage our expertise to help you identify opportunities in your market to make the most of AI.",
    iconSvg: <SvgAi gradient={svgDef} fill={customFill} height={"5em"} width={"5em"} />,
    iconSvgAlt: <SvgAi fill={fill} height={"1em"} width={"1em"} />,
  },
  {
    id: "business",
    title: "Business Strategy",
    description:
      "Properly positioning your product is the key to differentiating your offerings from competitors. We will help you create a bright and enduring signal in a market full of noise.",
    iconSvg: <SvgGoal gradient={svgDef} fill={customFill} height={"5em"} width={"5em"} />,
    iconSvgAlt: <SvgGoal fill={fill} height={"1em"} width={"1em"} />,
  },
];